
const apiBaseUrl = window.location.origin + "/api/v1"
const rootUrl = window.location.protocol +
                "//" +
                window.location.href.split("/")[2] + "/"

const api = {
  activeStorageDirectUploadUrl() {
    return rootUrl + "/rails/active_storage/direct_uploads"
  },
  rootUrl() {
    return rootUrl
  },
  v1: {
    quizzesUrl() {
      return apiBaseUrl + "/quizzes"
    },
    subscribedQuizzesUrl() {
      return apiBaseUrl + "/subscribed_quizzes"
    },
    schoolQuizzesUrl(schoolId) {
      return `${apiBaseUrl}/schools/${schoolId}/quizzes`
    },
    subjectsBatch() {
      return apiBaseUrl + "/subjects_batch"
    },
    groupsUrl() {
      return apiBaseUrl + "/groups.json"
    },
    groupUrl(id) {
      return apiBaseUrl + "/groups/" + id + ".json"
    },
    groupImportFromFile() {
      return apiBaseUrl + "/groups/import_from_file"
    },
    usersUrl(format = ".json") {
      return apiBaseUrl + "/users" + format
    },
    userUrl(id, format = ".json") {
      return apiBaseUrl + "/users/" + id + format
    },
    userScoresUrl(id, format = ".json") {
      return apiBaseUrl + "/users/" + id + "/scores" + format
    },
    userSelfUrl() {
      return apiBaseUrl + "/users/self"
    },
    userResetPasswordUrl(id) {
      return apiBaseUrl + "/users/" + id + "/reset_password"
    },
    userImportFromFile() {
      return apiBaseUrl + "/users/import_from_file"
    },
    quizDraftUrl(quizId, format = ".json") {
      return apiBaseUrl + "/quizzes/" + quizId + "/draft" + format
    },
    quizUrl(quizId, format = ".json") {
      return apiBaseUrl + "/quizzes/" + quizId + format
    },
    scoreUrl(scoreId, format = ".json") {
      return apiBaseUrl + "/scores/" + scoreId + format
    },
    scoresSummaryUrl(scoreId, format = ".json") {
      return apiBaseUrl + `/scores/${scoreId}/summary` + format
    },
    moodleCoursesUrl(format = ".json") {
      return apiBaseUrl + "/moodle/courses" + format
    },
    moodleCategoriesUrl(format = ".json") {
      return apiBaseUrl + "/moodle/categories" + format
    },
    emailsUrl(format = ".json") {
      return apiBaseUrl + "/emails" + format
    },
    schoolsUrl(format = ".json") {
      return apiBaseUrl + "/schools" + format
    },
    schoolUrl(id, format = ".json") {
      return apiBaseUrl + "/schools/" + id + format
    },
    userLoginUrl(format = ".json") {
      return apiBaseUrl + "/sessions" + format
    },
    passwordLessLoginUrl(format = ".json") {
      return apiBaseUrl + "/users/login_with_email" + format
    },
    userNewLoginUrl(format = ".json") {
      return apiBaseUrl + "/user/login" + format
    },
    resetPasswordUrl(format = ".json") {
      return apiBaseUrl + "/passwords" + format
    },
    tagsUrl(format = ".json") {
      return apiBaseUrl + "/quizzes/tags" + format
    },
    quizzesAttemptSummariesUrl() {
      return apiBaseUrl + "/quizzes/attempt_summaries"
    },
    quizzesAttemptSummaryUrl(id) {
      return apiBaseUrl + "/quizzes/attempt_summaries/" + id
    },
  },
  legacy: {
    logout() { return rootUrl + "/users/sign_out"},
    evalmeeStudentUrl() { return rootUrl + "/evalmee" },
  },
}

export default api
