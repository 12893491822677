import store from "../../../index"
import { QuizNode } from "../../../../behaviors/quiz/quiz_node"
import { QuestionBehaviorFor } from "../../../../behaviors/quiz/question/question_behavior_for"
import { Answer } from "../../../../models/answerModel"


class InstanceNode extends QuizNode{
  constructor(node, children = [], userId, parent = null) {
    super()
    this.type = this.nodeType()
    this.attributes = node.attributes
    this.links = node.attributes.links
    this.children = children
    this.userId = userId
    this.parent = { attributes: parent }
    this.id = node.id
  }

  /**
   * @return {boolean}
   */
  get isAnswered() {
    return this.children.filter(c => c.isAnswerable).every( q => q.isAnswered)
  }

  get isAnsweredOrSkipped() {
    return this.children.filter(c => c.isAnswerable).every( q => q.isAnsweredOrSkipped)
  }

  /**
   * @returns {number}
   */
  get progressPercent() {
    return this.answeredQuestionsCount / this.questionsCount * 100
  }

}

class SectionInstance extends InstanceNode {

  nodeType() { return "section" }

  /**
   * @return {ExerciseInstance[]}
   */
  get exercises() { return this.children.filter(c => c.type === "exercise")}

  /**
   * @return {SectionInstance[]}
   */
  get sections() { return this.children.filter(c => c.type === "section")}

  /**
   * @return {ExerciseInstance}
   */
  get firstUnansweredExercise() {
    return this.exercises.find(e => !e.isAnsweredOrSkipped) ||
    this.sections.find( s => !s.isAnsweredOrSkipped)?.firstUnansweredExercise
  }

  get answeredQuestionsCount() {
    return this.children.reduce( (acc, c) => acc + c.answeredQuestionsCount, 0)
  }

  get questionsCount() {
    return this.children.reduce( (acc, c) => acc + c.questionsCount, 0)
  }

}

class ExerciseInstance extends InstanceNode{

  nodeType() { return "exercise" }

  /**
   * @return {QuestionInstance[]}
   */
  get questions() { return this.children.filter(c => c.type === "question")}
  get requireExerciseValidation() {
    return this.questionsCount > 1 ||
           this.questions.some( q => q.attributes.allow_attachment)
  }
  get section() { return this.parent}
  get isImplicit() {
    if (this.children.length > 1) return false
    if (!["", null].includes(this.attributes.description)) return false

    return ["", null].includes(this.attributes.name)
  }
  get displayName() {
    return this.isImplicit ?
      this.children[0].attributes.name :
      this.attributes.name
  }
  get questionsCount() {
    return this.children.filter(q => q.isAnswerable).length
  }
  get answeredQuestionsCount() {
    return this.children.filter(q => q.isAnswered).length
  }

  skipQuestionsWithoutAnswer({ quizzesAttemptSummary }) {
    this.children.filter(q => !q.answer && q.isAnswerable).forEach( q => q.createEmptyAnswer({ quizzesAttemptSummary }))
  }
}

class QuestionInstance extends InstanceNode{

  nodeType() { return "question" }

  /**
   * @return {ChoiceInstance[]}
   */
  get choices() { return this.children.filter(c => c.type === "choices")}


  /**
   * @return {boolean}
   */
  get isAnswered() {
    if(!this.answer) return false

    return !this.isSkipped
  }

  get isAnsweredOrSkipped() {
    return !!this.answer
  }

  get isSkipped() {
    if(!this.answer) return false

    return new Answer(this.answer).isSkipped
  }

  get answer() {
    return store
      .getters
      .answerByQuestionAndUserId(this.attributes.id, this.userId)
  }

  get isMcq() { return this.attributes?.type === "mcq"}
  get isOpen() { return this.attributes?.type === "open"}
  get isSpreadsheet() { return this.attributes?.type === "spreadsheet"}
  get isText() { return this.attributes?.type === "text"}

  get indexable() { return this.behavior().indexable }

  get isAnswerable() { return this.behavior().isAnswerable }

  get allowMultipleChoices() { return this.behavior().allowMultipleChoices }

  get minHeight() { return this.behavior().minHeight }

  /**
   * @param {Number} position
   * @param {Number} questionsCount
   * @param {Boolean} paper
   *
   * @return {String}
   */
  subtitle(position, questionsCount, paper) {
    return this.behavior().subtitle(position, questionsCount, paper)
  }

  /**
   * @return {String, undefined}
   * @param {Boolean} withCount
   */
  subtitleInstructions({ withCount }) {
    return this.behavior().subtitleInstructions({
      correctChoicesCount: this.correctChoicesCount(),
      withCount,
    })
  }

  behavior() { return new QuestionBehaviorFor(this).behavior }

  correctChoicesCount() { return this.attributes?.correct_choices_count }

  createEmptyAnswer({ quizzesAttemptSummary }) {
    store.dispatch("createAnswer",{
      quizzesAttemptSummary,
      question: this,
      choices_ids: [],
      content: "",
      save: true,
    })
  }
}


class  ChoiceInstance extends InstanceNode{

  nodeType() { return "choice" }

}


const  parseInstance = (instance) => {
  const rootNode = instance?.quiz_data
  if(rootNode == null) return {}
  return parseNodes([rootNode], instance.user_id)[0]
}

// TODO: This function should be removed when paperQuizSheet will be merged with
// quizInstance in the backend
const  parseQuizSheet = (instance) => {
  const rootNode = instance?.quiz_data?.snapshot
  if(rootNode == null) return {}
  return parseNodes([rootNode], instance.user_id)[0]
}

/**
 *
 * @param {Object[]} nodes
 * @param {Number}   userId
 * @param {Object}   parent
 * @return {*}
 */
const parseNodes = (nodes, userId, parent = {}) => {
  return (nodes).map(node => {
    switch (node.type) {
    case "section" :  return new SectionInstance(
      node,
      parseNodes(
        [...(node.attributes?.sections || [] ), ...(node.attributes?.exercises || [] )],
        userId,
        node.attributes
      ),
      userId
    )
    case "exercise" : return new ExerciseInstance(node, parseNodes(node.attributes?.questions, userId), userId, parent)
    case "question" : return new QuestionInstance(node, parseNodes(node.attributes?.choices, userId), userId)
    case "choice" :   return new ChoiceInstance(node, [], userId)
    }
  })
}

// const process = (draft) => {
//   console.log({draft})
//   if(draft.content == null) return true
//   return parseInstance(draft.content).every(node => node.hasValidChildren())
// }



export default {
  parseInstance,
  parseQuizSheet,
  SectionInstance,
  ExerciseInstance,
  QuestionInstance,
  ChoiceInstance,
  parseNodes,
}
