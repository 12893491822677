import * as types from "../../mutation-types"
import axios from "axios"
import api from "../../../api"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  scores: [],
  scoresCount: 0,
  scoresLoading: false,
}

// getters
const getters = {
  scores: state => state.scores,
  scoresCount: state => state.scoresCount,
  scoreById: state => (scoreId) => {
    return state.scores.find(s => s.id === scoreId)
  },
  scoresByQuizId: state => (quizId) => {
    return state.scores.filter(s => s.quiz_id === quizId)
  },
  scoreByQuizAndUserId: state => (quizId, userId) => {
    return state.scores.find(s =>
      s.quiz_id === quizId
        && s.user_id === userId
    )
  },
  scoresLoading: state => state.scoresLoading,
  scoreBySubscription: (state, getters, rootState, rootGetters) => (subscription) => {
    const userId = rootGetters
      .schoolsUsers
      .find(su => su.id === subscription.schools_user_id)
      ?.user_id

    console.log("scoreBySubscription", subscription, userId)

    return getters.scoreByQuizAndUserId(subscription.quiz_id, userId)
  },
  scoresBySchoolUser: (state) => (schoolsUser) =>
    state.scores.filter(s => s.schools_user_id === schoolsUser.id),
}

// actions
const actions = {
  fetchScoresByQuiz({ commit }, quiz) {
    commit(types.SCORES_LOADING, true)

    return axios
      .get(quiz?.links?.scores)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORES, jsonApii.getData(response.data))
        commit(types.SCORES_LOADING, false)
      })
  },
  fetchScoresByQuizWithFilter({ commit }, { quiz,  id, filter, pagination, sort, search } = { id: null, filter: {}, pagination: {}, sort: {}, search: {} }) {
    commit(types.SCORES_LOADING, true)

    return axios
      .get(
        quiz?.links?.scores_summaries,
        {
          params: {
            filter,
            pagination,
            sort,
            search,
          },
          paramsSerializer: jsonApii.toFilters,
        },
      )
      .then( response => {
        const scores = jsonApii.getData(response.data)
        commit(types.CREATE_OR_UPDATE_SCORES, scores)
        commit(types.SCORES_LOADING, false)
        const meta = jsonApii.getMeta(response.data)
        return {
          items: scores,
          totalCount: meta.total,
          statistics: meta.statistics,
        }
      })
  },
  fetchScoresByUser({ commit }, { id, filter, pagination, sort, search } = { id: null, filter: {}, pagination: {}, sort: {}, search: {} }) {
    commit(types.SCORES_LOADING, true)

    return axios
      .get(api.v1.userScoresUrl(id), {
        params: {
          filter,
          pagination,
          sort,
          search,
        },
        paramsSerializer: jsonApii.toFilters,
      })
      .then(response => {
        jsonApii.getData(response.data).forEach(score => {
          commit(types.CREATE_OR_UPDATE_SCORE, score)
        })
        commit(types.SCORES_LOADING, false)
        commit(types.UPDATE_SCORES_COUNT, jsonApii.getMeta(response.data))
        return jsonApii.getData(response.data)
      })
  },
  fetchScoresSummariesBySchoolsUser({ commit }, { schoolsUser, filter, pagination, sort, search } = { id: null, filter: {}, pagination: {}, sort: {}, search: {} }) {
    commit(types.SCORES_LOADING, true)

    return axios
      .get(schoolsUser.links.scores_summaries, {
        params: {
          filter,
          pagination,
          sort,
          search,
        },
        paramsSerializer: jsonApii.toFilters,
      })
      .then(response => {
        jsonApii.getData(response.data).forEach(score => {
          commit(types.CREATE_OR_UPDATE_SCORE, score)
        })
        commit(types.SCORES_LOADING, false)
        commit(types.UPDATE_SCORES_COUNT, jsonApii.getMeta(response.data))

        jsonApii.getIncluded(response.data,"quiz")
          .forEach( quiz => commit(types.CREATE_OR_UPDATE_QUIZZ, quiz, { root: true }))

        commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, jsonApii.getIncluded(response.data,"quizzes_subscription"))

        console.log("fetchScoresSummariesBySchoolsUser", jsonApii.getData(response.data))

        return jsonApii.getData(response.data)
      })
  },
  fetchScoreSummary({ commit }, { scoreId }) {
    axios
      .get(api.v1.scoresSummaryUrl(scoreId))
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE,jsonApii.getData(response.data))
        commit(
          types.UPDATE_ANSWERS_NEW,
          jsonApii.getIncluded(response.data,"answer"),
          { root: true }
        )
        commit(
          types.CREATE_OR_UPDATE_STUDENT_EVENTS,
          jsonApii.getIncluded(response.data,"student_event"),
          { root: true }
        )
        commit(
          types.UPDATE_SIMILARITY_CHECK_SUBMISSIONS,
          jsonApii.getIncluded(response.data, "similarity_check_submission")
        )
      }).catch((_error) => {})
  },
  fetchScore({ commit }, { scoreId }) {
    return axios
      .get(api.v1.scoreUrl(scoreId))
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE,jsonApii.getData(response.data))
        commit(
          types.UPDATE_ANSWERS_NEW,
          jsonApii.getIncluded(response.data,"answer"),
          { root: true }
        )
        commit(
          types.CREATE_OR_UPDATE_STUDENT_EVENTS,
          jsonApii.getIncluded(response.data,"student_event"),
          { root: true }
        )
        commit(
          types.UPDATE_SIMILARITY_CHECK_SUBMISSIONS,
          jsonApii.getIncluded(response.data, "similarity_check_submission")
        )
      }).catch((_error) => {})
  },
  createScore({ commit }, { quiz, quizzesSessionId }){
    axios
      .post(quiz.links.scores,
        { quizzes_session_id: quizzesSessionId }

      ).then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getData(response.data))
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getIncluded(response.data,"score_summary"),
        )
      })
  },
  destroyScore({ commit, rootGetters }, score) {
    axios
      .delete(score.links.self)
      .then( () => {
        commit(types.DELETE_SCORE, score)

        let quizInstance = rootGetters.quizInstanceByQuizId(score.quiz_id, score.user_id)
        commit(types.DELETE_QUIZ_INSTANCE, quizInstance, { root: true })
        commit(types.FLUSH_ANSWERS)

      })
  },
  resetScoreSummary({ commit }, scoreSummary) {
    return axios
      .delete(scoreSummary.links.self)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getData(response.data))
      })
  },
  updateScore({ commit }, score) {
    return axios
      .patch(
        score.links.self,
        score,
        { transformRequest: [
          (data) =>  jsonApii.nullToEmptyString(data),
        ].concat(axios.defaults.transformRequest) }
      )
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getData(response.data))
        jsonApii.getIncluded(response.data,"score_summary").forEach( summary =>
          commit(types.CREATE_OR_UPDATE_SCORE, summary)
        )
      })
  },
  updateScoreBySummary({ commit }, scoreSummary) {
    return axios
      .patch(
        scoreSummary.links.score,
        scoreSummary,
        { transformRequest: [
          (data) =>  jsonApii.nullToEmptyString(data),
        ].concat(axios.defaults.transformRequest) }
      )
      .then( response => {
        jsonApii.getIncluded(response.data,"score_summary").forEach( summary =>
          commit(types.CREATE_OR_UPDATE_SCORE, summary)
        )
      })
  },
  handleScoreEvent({ commit }, { score, event }) {
    axios
      .post(score.links.handle_event, score, { params: { event } })
      .then( response => {
        jsonApii.getIncluded(response.data,"score_summary").forEach( summary =>
          commit(types.CREATE_OR_UPDATE_SCORE, summary)
        )
      })
  },

  updateAllLateAnswers({ commit }, { score, accept }) {
    axios
      .post(score.links.update_all_late_answers, { accept })
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getData(response.data))
        jsonApii.getIncluded(response.data,"score_summary").forEach( summary =>
          commit(types.CREATE_OR_UPDATE_SCORE, summary)
        )
      })
  },

  createProctoringReport({ commit }, score) {
    return axios
      .post(score.links.build_proctoring_report, score)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getData(response.data))
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getIncluded(response.data,"score_summary"),
        )
      })
  },
  createProctoringReportV2({ commit }, score) {
    return axios
      .post(score.links.build_proctoring_report_v2, score)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_SCORE, jsonApii.getData(response.data))
        jsonApii.getIncluded(response.data,"score_summary").forEach( summary =>
          commit(types.CREATE_OR_UPDATE_SCORE, summary)
        )
      })
  },

}

// mutations
const mutations = {
  [types.UPDATE_SCORES](state, scores){
    state.scores = scores
  },
  [types.UPDATE_SCORES_COUNT](state, meta){
    state.scoresCount = meta.total
  },
  [types.DELETE_SCORE]({ scores }, score){
    let index = scores.findIndex(e => e.id === score.id)
    scores.splice(index, 1)
  },
  [types.SCORES_LOADING](state, status){
    state.scoresLoading = status
  },
  [types.CREATE_OR_UPDATE_SCORE](state, score){
    VuexHelpers.createOrUpdate(state.scores, score)
  },
  [types.CREATE_OR_UPDATE_SCORES](state, scores){
    scores.forEach(score => {
      VuexHelpers.createOrUpdate(state.scores, score)
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
