const lodashReplacementHelper = {
  debounce(func, wait, immediate) {
    var timeout
    return function() {
      var context = this, args = arguments
      clearTimeout(timeout)
      timeout = setTimeout(function() {
        timeout = null
        if (!immediate) func.apply(context, args)
      }, wait)
      if (immediate && !timeout) func.apply(context, args)
    }
  },

  /**
   * Return a random item from an array
   * @param {Array} array
   */
  sample(array) {
    return array[~~(Math.random() * array.length)]
  },
}

export default lodashReplacementHelper
